<template>
      <v-container
        fluid
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Home section prices <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 1
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Vector Windows</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Color</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Almond"
                                    v-model="price_section_one.column_1"
                                />
                            </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Clay"
                                    v-model="price_section_one.column_2"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Grids</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Front Only"
                                    v-model="price_section_one.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="All Windows"
                                    v-model="price_section_one.column_4"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Garage Door</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Paint</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Paint"
                                    v-model="price_section_one.column_5"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Dead Bolts</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Style</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Venetian"
                                    v-model="price_section_one.column_6"
                                />
                            </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Satin Nickel"
                                    v-model="price_section_one.column_7"
                                />
                            </v-col>
                        <v-col cols="12" class="text-right">
                    </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 2
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Appliances</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Microwave</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Vented Hood"
                                    v-model="price_section_two.column_8"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Dryer</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Gas"
                                    v-model="price_section_two.column_9"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Stove</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Gas"
                                    v-model="price_section_two.column_10"
                                />
                            </v-col>
                        <v-col cols="12" class="text-right">
                    </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 3
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Outside</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Irrigation</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Water Spigot"
                                    v-model="price_section_three.column_11"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Deck</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Additional Length Per Foot"
                                    v-model="price_section_three.column_12"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Garage</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Floor Drain"
                                    v-model="price_section_three.column_13"
                                />
                            </v-col>
                        <v-col cols="12" class="text-right">
                    </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 4
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Fireplace</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Natural Gas</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Fan"
                                    v-model="price_section_four.column_14"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>LP Gas</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Remote"
                                    v-model="price_section_four.column_15"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Central Air Conditioning</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Amp</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="200"
                                    v-model="price_section_four.column_16"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Garage</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col
                                cols="4" md="3"
                            >
                                <v-text-field
                                    label="Service Door"
                                    v-model="price_section_four.column_17"
                                />
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="1/2 HSP W/(2) Transmitters"
                                    v-model="price_section_four.column_18"
                                />
                            </v-col>
                        <v-col
                                cols="5"
                            >
                                <v-text-field
                                    label="8ft Height Doors *1/2 HSP Chain Drive"
                                    v-model="price_section_four.column_19"
                                />
                            </v-col>
                      </v-row>
                      <v-divider/>
                      <v-row>
                        <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Keyless Entry"
                                    v-model="price_section_four.column_20"
                                />
                            </v-col>
                        <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="My Q For Smart Phones"
                                    v-model="price_section_four.column_21"
                                />
                            </v-col>
                        <v-col

                                cols="5"
                            >
                                <v-text-field
                                    label="8ft Height Both Doors"
                                    v-model="price_section_four.column_22"
                                />
                            </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="Glass Top Row Both Doors"
                                    v-model="price_section_four.column_23"
                                />
                            </v-col>
                        <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="Decorative Outside Hardware"
                                    v-model="price_section_four.column_24"
                                />
                            </v-col>
                      </v-row>
                     <v-row>
                        <v-col
                          md="4" >
                        <h3>Insulated Garage Doors</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col
                                cols="4" md="3"
                            >
                                <v-text-field
                                    label="16' Door"
                                    v-model="price_section_four.column_25"
                                />
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="8' Door"
                                    v-model="price_section_four.column_26"
                                />
                            </v-col>
                      </v-row>
                     <v-row>
                        <v-col
                          md="4" >
                        <h3>Thermal Steel Interior Skin</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col
                                cols="4" md="3"
                            >
                                <v-text-field
                                    label="16' Door"
                                    v-model="price_section_four.column_27"
                                />
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="8' Door"
                                    v-model="price_section_four.column_28"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Water Softener</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col
                                cols="4" md="3"
                            >
                                <v-text-field
                                    label="Loop Install"
                                    v-model="price_section_four.column_29"
                                />
                            </v-col>
                        <v-col
                                cols="4" md="3"
                            >
                                <v-text-field
                                    label="Loop and hookup"
                                    v-model="price_section_four.column_40"
                                />
                            </v-col>
                            <v-col
                                cols="4"
                            >
                                <v-text-field
                                    label="Water Softener Unit (42,000 Grain NSC42)"
                                    v-model="price_section_four.column_30"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Water Heater</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col
                                cols="6" md="6"
                            >
                                <v-text-field
                                    label="Water Heater With Power Ventilation"
                                    v-model="price_section_four.column_31"
                                />
                            </v-col>
                        <v-col cols="12" class="text-right">
                    </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section A
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Flooring Allowances</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="1" >
                        <h4>Carpet</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    v-model="price_section_a.column_32"
                                />
                            </v-col>
                        <v-col
                          cols="1" >
                        <h4>Vinyl</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    v-model="price_section_a.column_33"
                                />
                            </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section B
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Cabinets</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="1" >
                        <h4>Drawer Handles</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    v-model="price_section_b.column_34"
                                />
                            </v-col>
                        <v-col cols="12" class="text-right">
                    </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 7
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Plumbing Fixtures</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Stool</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Elongated Height"
                                    v-model="price_section_seven.column_35"
                                />
                            </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Elongated/Comfort Height"
                                    v-model="price_section_seven.column_36"
                                />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          md="4" >
                        <h3>Homeowner Supplying Own Plumbing Fixtures</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Trip Charge Per Trip"
                                    v-model="price_section_seven.column_37"
                                />
                            </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Hour Labor Rate"
                                    v-model="price_section_seven.column_38"
                                />
                            </v-col>
                        <v-col cols="12" class="text-right">
                    </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 8
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          md="1" >
                        <h4>Mirrors</h4>
                        </v-col>
                            <v-col
                                cols="3"
                            >
                                <v-text-field
                                    label="Installation Fee"
                                    v-model="price_section_eight.column_39"
                                />
                            </v-col>
                      </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                <v-form>
                    <v-container class="py-1">
                        <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save All Sections</v-btn>
                    </v-col>
                     </v-container>
                </v-form>
                <v-spacer/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'sectionOne',
    data() {
    return {
      alert:false,
      price_section_one: {
        column_1: '',
        column_2: '',
        column_3: '',
        column_4: '',
        column_5: '',
        column_6: '',
        column_7: ''
      },
      price_section_two: {
        column_8: '',
        column_9: '',
        column_10: ''
      },
      price_section_three: {
        column_11: '',
        column_12: '',
        column_13: ''
      },
      price_section_four: {
        column_14: '',
        column_15: '',
        column_16: '',
        column_17: '',
        column_18: '',
        column_19: '',
        column_20: '',
        column_21: '',
        column_22: '',
        column_23: '',
        column_24: '',
        column_25: '',
        column_26: '',
        column_27: '',
        column_28: '',
        column_29: '',
        column_30: '',
        column_31: '',
        column_40: ''
      },
      price_section_a: {
        column_32: '',
        column_33: ''
      },
      price_section_b: {
        column_34: ''
      },
      price_section_seven: {
        column_35: '',
        column_36: '',
        column_37: '',
        column_38: ''
      },
      price_section_eight: {
        column_39: ''
      }
    }
  },
  created () {
    this.getSection()
    },
    methods:{

        getSection() {
            axios
            .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
            {
              // params: {
                //     home_id: this.home_id,
            //     section_id: this.section_id
            // }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.price_section_one = JSON.parse(result['SectionOne'])
                    this.price_section_two = JSON.parse(result['SectionTwo'])
                    this.price_section_three = JSON.parse(result['SectionThree'])
                    this.price_section_four = JSON.parse(result['SectionFour'])
                    this.price_section_a = JSON.parse(result['SectionA'])
                    this.price_section_b = JSON.parse(result['SectionB'])
                    this.price_section_seven = JSON.parse(result['SectionSeven'])
                    this.price_section_eight = JSON.parse(result['SectionEight'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        updateSection(){
            var body ={
              "section_one":JSON.stringify(this.price_section_one),
              "section_two":JSON.stringify(this.price_section_two),
              "section_three":JSON.stringify(this.price_section_three),
              "section_four":JSON.stringify(this.price_section_four),
              "section_a":JSON.stringify(this.price_section_a),
              "section_b":JSON.stringify(this.price_section_b),
              "section_seven":JSON.stringify(this.price_section_seven),
              "section_eight":JSON.stringify(this.price_section_eight),
            }

            axios
            .post(process.env.VUE_APP_UPDATE_HOME_SECTION_PRICES_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
    }
}
</script>